<template>
    <div class="notification-list">
        <md-table-card style="margin:auto">
            <md-card-header style="display:flex;align-items:center">
                <h1 class="md-title">Notification List</h1>
                <span style="margin-right:20px;">{{now}}</span>
                <md-button v-if="false" class="md-raised" @click="triggerScheduled">Trigger Scheduled [TEST]</md-button>
            </md-card-header>
            <md-card-content>
                <md-table>
                    <md-table-header>
                        <md-table-row>
                            <md-table-head>Subject</md-table-head>
                            <md-table-head>Delivery</md-table-head>
                            <md-table-head>Setting</md-table-head>
                            <md-table-head>Recipient</md-table-head>
                            <md-table-head>Notification Status</md-table-head>
                            <md-table-head>Updated</md-table-head>
                            <md-table-head>Action</md-table-head>
                        </md-table-row>

                    </md-table-header>
                    <md-table-body>
                        <md-table-row v-for="(item,ix) in list" :key="ix">
                            <md-table-cell>{{item.subject}}</md-table-cell>
                            <md-table-cell>{{item.delivery}}</md-table-cell>
                            <md-table-cell>
                                <template v-if="item.delivery=='immediately'">
                                    <span>{{item.immediatelySetting.event}}</span>
                                </template>
                                <template v-if="item.delivery=='scheduled'">
                                    <span>{{item.scheduledSetting.count}} </span>
                                    <span>{{item.scheduledSetting.unit}} </span>
                                    <span>{{item.scheduledSetting.when}} </span>
                                    <span>{{item.scheduledSetting.status}} </span>
                                    <span>{{item.scheduledSetting.key}}</span>
                                </template>
                            </md-table-cell>
                            <md-table-cell>{{getRecipient(item)}}</md-table-cell>
                            <md-table-cell>{{item.active?'enabled':'disabled'}}</md-table-cell>
                            <md-table-cell>{{item.updatedAt | date}}</md-table-cell>
                            <md-table-cell>
                                <md-button class="md-raised md-primary" @click="goEdit(item)">Edit</md-button>
                                <md-button class="md-raised md-warn" @click="confirmRemove(item)">Delete</md-button>
                            </md-table-cell>
                        </md-table-row>

                    </md-table-body>
                </md-table>
            </md-card-content>
        </md-table-card>

        <md-dialog-confirm md-title="Attention" md-content-html="Confirm to delete?" md-ok-text="yes" md-cancel-text="cancel" @close="confirmed" ref="delConfirm">
        </md-dialog-confirm>

    </div>
</template>

<script>
import moment from "moment-timezone";
export default {
    data() {
        return {
            list: [],
            now: null
        };
    },
    computed: {
        getRecipient: () => notification => {
            return Object.keys(notification.recipient)
                .filter(key => notification.recipient[key])
                .map(key => ({ staffs: "staff" }[key] || key))
                .join(" ");
        }
    },
    methods: {
        async getList() {
            var result = await this.$store.dispatch("crud/get", {
                api: "notifications/",
                params:{
                    pageSize:0
                }
            });
            this.list = result.data;
        },
        goEdit(item) {
            this.$router.push(`/admin/notifications/${item._id}`);
        },
        async remove(item) {
            var result = await this.$store.dispatch("crud/delete", { api: `notifications/${item._id}` });
            this.getList();
        },
        confirmRemove(item) {
            this.$refs["delConfirm"].open();
            this.$refs["delConfirm"].data = item;
        },
        confirmed(type) {
            if (type == "ok") {
                var item = this.$refs["delConfirm"].data;
                this.remove(item);
            }
        },
        async triggerScheduled() {
            var result = await this.$store.dispatch("crud/get", {
                api: "notifications/triggerScheduled"
            });
            this.$store.commit("setMessage", result);
        }
    },
    mounted() {
        this.getList();
    },
    created() {
        setInterval(() => {
            this.now = moment().format("ddd MMM Do YYYY HH:mm:ss");
        }, 1000);
    }
};
</script>
<style lang="less">
.notification-list {
    .md-table .md-table-cell .md-button {
        width: 100px;
        margin-left: 0;
    }

    .md-table .md-table-cell.md-has-action {
        width: 220px;
    }
}
</style>