<template>
    <div class="notification-mass">
        <edit title="Mass Email" api="notifications/mass" :fs="notificationMassFields" @saved="saved"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { notificationMassFields } from "@/config";

export default {
    components: {
        Edit
    },
    computed: {
        notificationMassFields() {
            return notificationMassFields;
        }
    },
    methods: {
        async saved() {
            this.$store.commit("setMessage", "Email Mass Success");
        },
        cancel() {
            this.$router.push("/admin/notification");
        }
    },
    mounted() {
        this.obj = {
            subject: "Re.",
            recipient: {
                users: true
            },
            template: "email content .."
        };
    }
};
</script>

<style lang="less">
.notification-mass {
}
</style>