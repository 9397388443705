<template>
    <div class="notification-index">
        <md-tabs @change="tabChange">
            <md-tab :md-active="tabIndex==0" md-label="Notifications">
                <notification-list ref="list" v-if="tabIndex==0"></notification-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" md-label="Add Notification">
                <notification-edit ref="add" v-if="tabIndex==1"></notification-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==2" md-label="Mass E-mail">
                <notification-mass ref="mass" v-if="tabIndex==2"></notification-mass>
            </md-tab>
            <md-tab :md-active="tabIndex==3" md-label="Edit Notification" v-if="tabIndex==3">
                <notification-edit :id="id" ref="edit" v-if="tabIndex==3"></notification-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import NotificationList from "@/components/Notification/NotificationList";
import NotificationEdit from "@/components/Notification/NotificationEdit";
import NotificationMass from "@/components/Notification/NotificationMass";

export default {
    props: ["id"],
    components: {
        NotificationList,
        NotificationEdit,
        NotificationMass
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else if (this.id == "mass") {
                    this.tabIndex = 2;
                } else {
                    this.tabIndex = 3;
                }
                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }
            if (index == 0) {
                this.$router.push("/admin/notifications");
            }
            if (index == 1) {
                this.$router.push("/admin/notifications/new");
            }
            if (index == 2) {
                this.$router.push(`/admin/notifications/mass`);
            }
            if (index == 3) {
                this.$router.push(`/admin/notifications/${this.id}`);
            }
        }
    }
};
</script>

