<template>
    <div class="notification-edit">
        <form @submit.prevent="process" action v-if="!isFetching">
            <md-card style="max-width:1200px;margin:auto" v-if="obj">
                <md-card-header>
                    <h1 class="md-title">Edit/Add Notification</h1>
                </md-card-header>
                <md-card-content>
                    <md-input-container>
                        <label for>Subject</label>
                        <md-input v-model="obj.subject"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Delivery</label>
                        <md-radio md-value="immediately" v-model="obj.delivery">Immediately</md-radio>
                        <md-radio md-value="scheduled" v-model="obj.delivery">Scheduled</md-radio>
                    </md-input-container>
                    <md-input-container>
                        <label for>Type</label>
                        <md-radio @change="handleTypeSelect('order')" md-value="order" v-model="obj.type">Order</md-radio>
                        <md-radio @change="handleTypeSelect('account')" md-value="account" v-if="obj.delivery !== 'scheduled'" v-model="obj.type">Account</md-radio>
                        <md-radio @change="handleTypeSelect('appfee')" md-value="appfee" v-if="obj.delivery !== 'scheduled'" v-model="obj.type">AppFee</md-radio>
                        <md-radio @change="handleTypeSelect('qa')" md-value="qa" v-if="obj.delivery !== 'scheduled'" v-model="obj.type">QA</md-radio>
                    </md-input-container>
                    <md-input-container v-show="obj.delivery == 'immediately'">
                        <label for>Immdiately Setting</label>
                        <md-select v-model="obj.immediatelySetting.event">
                            <md-option :key="ix" :value="event" v-for="(event, ix) in immediatelyEvents[obj.type]">{{ event }}</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container v-show="obj.delivery == 'scheduled' && scheduleds[obj.type].active">
                        <label for>Scheduled Setting</label>
                        <md-select v-model="obj.scheduledSetting.count" v-show="scheduleds[obj.type].counts">
                            <md-option :key="ix" :value="count" v-for="(count, ix) in scheduleds[obj.type].counts">{{ count }}</md-option>
                        </md-select>
                        <md-select v-model="obj.scheduledSetting.unit" v-show="scheduleds[obj.type].units">
                            <md-option :key="ix" :value="unit" v-for="(unit, ix) in scheduleds[obj.type].units">{{ unit }}</md-option>
                        </md-select>
                        <md-select v-model="obj.scheduledSetting.when" v-show="scheduleds[obj.type].whens">
                            <md-option :key="ix" :value="when" v-for="(when, ix) in scheduleds[obj.type].whens">{{ when }}</md-option>
                        </md-select>
                        <md-select v-model="obj.scheduledSetting.status" v-show="scheduleds[obj.type].statuses">
                            <md-option :key="ix" :value="status" v-for="(status, ix) in scheduleds[obj.type].statuses">{{ status }}</md-option>
                        </md-select>
                        <md-select v-model="obj.scheduledSetting.key" v-show="scheduleds[obj.type].keys">
                            <md-option :key="ix" :value="key" v-for="(key, ix) in scheduleds[obj.type].keys">{{ key }}</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container>
                        <label for>Recipient</label>
                        <md-checkbox v-model="obj.recipient.admins">Admins</md-checkbox>
                        <md-checkbox v-model="obj.recipient.managers">Managers</md-checkbox>
                        <md-checkbox v-model="obj.recipient.vendors">Vendors</md-checkbox>
                        <md-checkbox v-if="obj.type !== 'appfee'" v-model="obj.recipient.staffs">Staff</md-checkbox>
                        <md-checkbox v-if="obj.type !== 'appfee'" v-model="obj.recipient.users">Users</md-checkbox>
                    </md-input-container>
                    <md-input-container>
                        <label for>Email</label>
                        <email-editor :wordList="wordList" v-model="obj.template"></email-editor>
                        <md-input type="hidden" v-model="obj.template"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Active</label>
                        <md-checkbox v-model="obj.active"></md-checkbox>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <md-button @click="cancel" class="md-raised" style="margin-right:20px">Cancel</md-button>
                    <md-button class="md-raised md-primary" type="submit">Save</md-button>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>
<script>
import { orderStatuses, paymentStatuses } from "@/config";

import EmailEditor from "@/components/EmailEditor";

export default {
    props: ["id"],
    components: {
        EmailEditor
    },
    data() {
        return {
            obj: null,
            isFetching: true
        };
    },
    computed: {
        // notificationTypes() {
        //     return ["order", "account", "appfee"];
        // },
        orderEvents() {
            return [].concat(
                orderStatuses.map(e => "Order " + e),
                paymentStatuses.map(e => "Payment " + e),
                ["Event Add Payment", "Event Refund Payment"]
            );
        },
        accountEvent() {
            return [
                //
                "Event Invite",
                "Event Staff Invite",
                "Event Forgot Password",
                "Event Reset Password",
                "Event Send Update Email",
                "Event Update Email Success",
                "Event Branch Create"
            ];
        },
        appfeeEvent() {
            return [
                //
                "Payment Success",
                "Payment Failed",
                "Refund Success",
                "Refund Failed"
            ];
        },
        qaEvent() {
            return ["QA Answered"];
        },
        immediatelyEvents() {
            return {
                order: this.orderEvents,
                account: this.accountEvent,
                appfee: this.appfeeEvent,
                qa: this.qaEvent
            };
        },
        scheduleds() {
            return {
                order: {
                    counts: Array.from({ length: 30 }, (v, i) => i + 1),
                    statuses: [].concat(orderStatuses.map(s => s + " Order")),
                    units: ["Day", "Week", "Month"],
                    whens: ["Before", "After"],
                    keys: ["StartDate", "EndDate"],
                    active: true
                },
                account: {
                    active: false
                },
                appfee: {
                    active: false
                },
                qa: {
                    active: false
                }
            };
        },
        emailImmediatelyWords() {
            return {
                order: [
                    "${ORDER_ORDERNO}",
                    "${ORDER_USER_NAME}",
                    "${ORDER_USER_EMAIL}",
                    "${ORDER_CREATOR_NAME}",
                    "${ORDER_CREATOR_EMAIL}",
                    "${ORDER_VENDOR_NAME}",
                    "${ORDER_VENDOR_EMAIL}",
                    // "${ORDER_ADDRESS_NAME}",
                    // "${ORDER_ADDRESS_EMAIL}",
                    "${ORDER_AMOUNT}", //TOTAL OF ALL
                    "${ORDER_AMOUNT_TOTAL}", //TOTAL OF ITEMS
                    "${ORDER_AMOUNT_SHIPPING}",
                    "${ORDER_AMOUNT_OFFHOUR}",
                    "${ORDER_AMOUNT_SECURITYDEPOSIT}",
                    "${ORDER_AMOUNT_DEPOSIT}",
                    "${ORDER_PAID}",
                    "${ORDER_STATUS}",
                    "${ORDER_PAYMENTSTATUS}",
                    "${ORDER_NOTE}",
                    "${ORDER_PDF_LINK}",
                    "${ORDER_ITEMS}", //HTML OF ITEMS
                    "${ORDER_ADDRESS}", //HTML OF ADDRESS

                    "${RECEIVER_NAME}",
                    "${RECEIVER_EMAIL}"
                ],
                account: [
                    //
                    "${UPDATE_EMAIL_URL}",
                    "${RESET_PASSWORD_URL}",
                    "${RECEIVER_EMAIL}",
                    "${RECEIVER_NAME}"
                ],
                appfee: ["${APP_FEE_AMOUNT}", "${PAYMENT_METHOD_LINK}"],
                qa: [
                    "${QUESTION}",
                    "${VENDOR_NAME}",
                    "${VENDOR_EMAIL}",
                    "${USER_NAME}",
                    "${USER_EMAIL}",
                    "${LAST_REPLY_CONTENT}",
                    "${LAST_REPLY_USER_NAME}",
                    "${LAST_REPLY_USER_EMAIL}",

                    "${RECEIVER_NAME}",
                    "${RECEIVER_EMAIL}"
                ]
            };
        },
        emailScheduledWords() {
            return {
                order: [
                    "${ORDER_ORDERNO}",
                    "${ORDER_USER_NAME}",
                    "${ORDER_USER_EMAIL}",
                    "${ORDER_CREATOR_NAME}",
                    "${ORDER_CREATOR_EMAIL}",
                    "${ORDER_VENDOR_NAME}",
                    "${ORDER_VENDOR_EMAIL}",
                    // "${ORDER_ADDRESS_NAME}",
                    // "${ORDER_ADDRESS_EMAIL}",
                    "${ORDER_AMOUNT}", //TOTAL OF ALL
                    "${ORDER_AMOUNT_TOTAL}", //TOTAL OF ITEMS
                    "${ORDER_AMOUNT_SHIPPING}",
                    "${ORDER_AMOUNT_OFFHOUR}",
                    "${ORDER_AMOUNT_SECURITYDEPOSIT}",
                    "${ORDER_AMOUNT_DEPOSIT}",
                    "${ORDER_PAID}",
                    "${ORDER_STATUS}",
                    "${ORDER_PAYMENTSTATUS}",
                    "${ORDER_NOTE}",
                    "${ORDER_PDF_LINK}",
                    "${ORDER_ITEMS}", //HTML OF ITEMS
                    "${ORDER_ADDRESS}", //HTML OF ADDRESS

                    "${ITEM_URL}", //LINK OF PRODUCT
                    "${ITEM_NAME}",
                    "${ITEM_VNAME}",
                    "${ITEM_IMAGE}",
                    "${ITEM_PRICE}",
                    "${ITEM_QUANTITY}",
                    "${ITEM_SUBTOTAL}",
                    "${ITEM_SUBDEPOSIT}",
                    "${ITEM_SUBPAID}",
                    "${ITEM_RENTALSTARTDATE}",
                    "${ITEM_RENTALENDDATE}",
                    "${ITEM_CARD}", //HTML OF ITEM

                    "${RECEIVER_NAME}",
                    "${RECEIVER_EMAIL}"
                ],
                account: [],
                appfee: [],
                qa: []
            };
        },
        wordList() {
            let result = [];

            if (this.obj) {
                if (this.obj.delivery == "immediately") {
                    result = this.emailImmediatelyWords[this.obj.type].slice();
                }
                if (this.obj.delivery == "scheduled") {
                    result = this.emailScheduledWords[this.obj.type].slice();
                }
                if (this.obj.immediatelySetting.event === "Event Add Payment") {
                    result.unshift("${ORDER_PAYMENT_AMOUNT}");
                }
                if (this.obj.immediatelySetting.event === "Event Refund Payment") {
                    result.unshift("${ORDER_REFUND_AMOUNT}");
                }
            }

            return result;
        }
    },
    watch: {
        id() {
            this.get();
        },
        "obj.delivery"(delivery) {
            if (delivery === "scheduled") {
                this.obj.type = "order";
            }
        }
    },
    methods: {
        handleTypeSelect(type) {
            this.$set(this.obj, "scheduledSetting", {});
            this.$set(this.obj, "immediatelySetting", {});
        },
        async get() {
            this.isFetching = true;
            if (!this.id) {
                return;
            }
            var result = await this.$store.dispatch("crud/get", {
                api: `notifications/${this.id}`
            });
            if (!result.scheduledSetting) {
                result.scheduledSetting = {};
            }
            this.obj = result;

            this.isFetching = false;
        },
        async save() {
            var result = await this.$store.dispatch("crud/post", {
                api: `notifications/`,
                data: this.obj
            });
            this.resetObj();
            this.$router.push("/admin/notifications");
        },
        async update() {
            var result = await this.$store.dispatch("crud/put", {
                api: `notifications/${this.id}`,
                data: this.obj
            });
            this.$router.push("/admin/notifications");
        },
        async process() {
            if (this.id) {
                this.update();
            } else {
                this.save();
            }
        },
        cancel() {
            this.resetObj();
            this.$emit("done");
        },
        resetObj() {
            this.isFetching = true;
            this.obj = {
                subject: "Re.",
                delivery: "immediately",
                type: "order",
                immediatelySetting: {
                    event: this.immediatelyEvents.order[0]
                },
                scheduledSetting: {
                    count: this.scheduleds.order.counts[0],
                    unit: this.scheduleds.order.units[0],
                    when: this.scheduleds.order.whens[0],
                    status: this.scheduleds.order.statuses[0],
                    key: this.scheduleds.order.keys[0]
                },
                recipient: {
                    users: true
                },
                template: "email content ..",
                active: true
            };
            this.isFetching = false;
        }
    },
    mounted() {
        if (this.id) {
            this.get();
        } else {
            this.resetObj();
        }
    }
};
</script>

<style lang="less">
.notification-edit {
    @import "../../mixins.less";
    .form();
    .md-input-container {
        label {
            min-width: 150px;
        }
    }
}
</style>
